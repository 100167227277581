<template>
  <div :style="{ position: 'relative' }">
    <Loading :active.sync="loading" :isFullPage="false"></Loading>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <div class="p-d-flex p-jc-row p-ai-center p-mb-2">
      <div><b>Grupo</b></div>
      <div class="p-ml-2">
        <Dropdown
          v-model="groupId"
          @input="groupChanged()"
          :options="listGroups"
          optionValue="id"
          optionLabel="name"
          :showClear="true"
        />
      </div>
    </div>
    <div v-for="category in categoriesAcl" :key="category.id">
      <div
        class="p-d-flex p-jc-row p-ai-center category"
        v-if="category.acls.length > 0"
      >
        <div v-if="category.parent_id" class="p-ml-1">
          {{ category.parent.name }} >
        </div>
        <div class="p-ml-1">
          <b>{{ category.name }}</b>
        </div>
      </div>
      <div
        class="p-d-flex p-jc-row p-ai-center p-ml-3 acls-list"
        v-for="acl in category.acls"
        :key="acl.id"
      >
        <div>
          <InputSwitch
            v-if="groupAcls[acl.id]"
            v-model="groupAcls[acl.id]"
            :disabled="true"
            :id="acl.id"
            style="'top: 3px;"
          />
          <InputSwitch
            v-else
            v-model="usersAcls[acl.id]"
            :id="acl.id"
            style="'top: 3px;"
            v-bind:class="[
              'acl-user',
            ]"
          />
        </div>
        <div class="p-ml-2">{{ acl.name }} | {{ acl.alias }}</div>
      </div>
    </div>
    <divider />
    <div class="p-d-flex p-jc-end">
      <Button
        :label="'Guardar'"
        icon="pi pi-check"
        class="p-button-success p-mr-2"
        @click="save"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-danger"
        @click="cancel"
      />
    </div>
  </div>
</template>
<script>
import aclsService from "../../services/acls.service";
import loading from "vue-loading-overlay";
export default {
  name: "setUserGroupAcls",
  components: {
    Loading: loading,
  },
  props: ["user"],
  watch: {
    user() {
      if (this.user != undefined) {
        this.groupId = this.user.group_id;
        this.getInfo();
        this.usersAcls = this.getUserAcls();
      }
    },
  },
  data() {
    return {
      loading: false,
      groupId: null,
      categoriesAcl: [],
      groupAcls: [],
      usersAcls: [],
      listGroups: [],
    };
  },
  productService: null,
  async created() {
    if (this.user != undefined) {
      this.groupId = this.user.group_id;
      await this.getInfo();
      this.usersAcls = await this.getUserAcls();
    }
  },
  methods: {
    async getInfo() {
      this.loading = true;
      if (this.groupId != null) {
        this.groupAcls = await this.getGroupAcls();
      }
      this.categoriesAcl = await aclsService.getCategoriesAcls();
      this.listGroups = await aclsService.getGroups();
      return (this.loading = false);
    },
    async getGroupAcls() {
      return aclsService.getGroupAcls(this.groupId).then((response) => {
        let groupAcls = {};
        response.map((acl) => {
          groupAcls[acl.id] = true;
        });
        return groupAcls;
      });
    },
    async getUserAcls() {
      return aclsService.getUserAcls(this.user.id).then((response) => {
        let userAcls = {};
        response.map((acl) => {
          userAcls[acl.id] = true;
        });
        return userAcls;
      });
    },
    groupChanged() {
      return this.getInfo();
    },
    cancel() {
      return this.$emit("cancel");
    },
    save() {
      this.loading = true;
      let aclsChecked = [];
      for (const aclId in this.usersAcls) {
        if (this.usersAcls[aclId] === true) {
          aclsChecked.push(parseInt(aclId));
        }
      }
      return aclsService
        .setUserAcls(this.user.id, { acls: aclsChecked })
        .then((response) => {
          this.loading = false;
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro ao alterar as acls do utilizador",
              detail: "",
              life: 3000,
            });
          }
        })
        .then(() => {
          return this.setGroup();
        });
    },
    setGroup() {
      if (this.groupId == null || this.groupId == this.user.group_id) {
        this.$toast.add({
          severity: "success",
          summary: `Utilizador atualizado com sucesso`,
          detail: ``,
          life: 3000,
        });
        this.loading = false;
        return this.$emit("setUserGroupAclsOk", {
          status: "ok",
        });
      }

      return aclsService
        .setUserGroup(this.user.id, this.groupId)
        .then((response) => {
          this.loading = false;
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro ao alterar as acls do utilizador",
              detail: "",
              life: 3000,
            });
          }
          this.$toast.add({
            severity: "success",
            summary: `Utilizador atualizado com sucesso`,
            detail: ``,
            life: 3000,
          });

          return this.$emit("setUserGroupAclsOk", {
            status: "ok",
            group: response,
          });
        });
    },
  },
};
</script>
<style>
.category {
  background-color: #ff9811;
  height: 30px;
  font-size: 16px;
}
.acls-list {
  height: 30px;
}
.p-inputswitch-checked.acl-user .p-inputswitch-slider {
  background-color: red!important;
}
</style>
