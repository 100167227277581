<template>
  <div class="card p-shadow-6">
    <h1>Acls - Utilizadores</h1>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog></ConfirmDialog>
    <div style="height: 65vh">
      <DataTable
        :value="listUsers"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        class="p-datatable-sm"
        :sortOrder="1"
        sortField="username"
        responsiveLayout="scroll"
        removableSort
        sortMode="single"
        :scrollable="true"
        scrollHeight="flex"
        :autoLayout="true"
      >
        <template #empty> Não existem Utilizadores. </template>
        <template #loading> A carregar. Por favor Aguarde... </template>
        <Column
          field="username"
          filterField="username"
          header="Nome"
          sortable
          filterMatchMode="contains"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="department"
          header="Departamento"
          filterField="department"
          sortable
          filterMatchMode="contains"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="group_id"
          header="Grupo"
          sortable
          filterField="group_id"
          dataType="boolean"
        >
          <template #body="slotProps">
            {{ slotProps.data.group_name }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="listGroups"
              optionValue="id"
              optionLabel="name"
              class="p-column-filter"
              :showClear="true"
            >
            </Dropdown>
          </template>
        </Column>
        <Column>
          <template #body="slotProps">
            <Button
              v-if="$store.state.auth.user.isEsa || can('setUserGroupAndAcls')"
              icon="pi pi-lock-open"
              v-tooltip.left="'Adicionar ACLs'"
              class="p-button-sm p-button-rounded p-button-info p-ml-1"
              @click="setGroupAcls(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </div>
    <Dialog
      :visible.sync="groupAcls.show"
      :style="{ width: $isMobile() ? '100vw' : '40vw' }"
      :header="
        'Utilizador ' +
        (groupAcls.user == undefined ? '' : groupAcls.user.username)
      "
      :modal="true"
      :contentStyle="{ overflow: 'auto' }"
    >
      <UserGroupAcls
        :user="groupAcls.user"
        v-on:cancel="cancelSetUserAcls"
        v-on:setUserGroupAclsOk="setUserGroupAclsOk"
      />
    </Dialog>
  </div>
</template>
<script>
import UserGroupAcls from "../components/acls/setUserGroupAcls.vue";
import "vue-loading-overlay/dist/vue-loading.css";
import aclsService from "../services/acls.service";
import { FilterMatchMode } from "primevue/api/";
export default {
  name: "AclsUsers",
  components: {
    UserGroupAcls,
  },
  data() {
    return {
      loading: true,
      listGroups: [],
      listUsers: [],
      filters: {
        username: { value: null, matchMode: FilterMatchMode.CONTAINS },
        department: { value: null, matchMode: FilterMatchMode.CONTAINS },
        group_id: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      groupAcls: { show: false },
    };
  },
  async created() {
    this.listGroups = await aclsService.getGroups();
    this.listUsers = await aclsService.getUsersList();
    this.loading = false;
  },
  methods: {
    setGroupAcls(user) {
      this.groupAcls.user = user;
      return (this.groupAcls.show = true);
    },
    cancelSetUserAcls() {
      return (this.groupAcls = { show: false });
    },
    setUserGroupAclsOk(response) {
      if (response.group) {
        this.groupAcls.user.group_id = response.group.id;
        this.groupAcls.user.group_name = response.group.name;
      }
      return this.cancelSetUserAcls();
    },
  },
};
</script>
